export const TRANSLATIONS_SV = {
    welcome: 'Välkommen',
    log_in_to_play: 'Logga in för att spela',
    odds: 'Odds',
    home: 'Home',
    live: 'Live/just nu',
    pool_x: 'PoolX',
    starting_soon: 'Startar snart',
    current: 'Aktuellt',
    promotion: 'Promotion',
    bet_history: 'Spelhistorik',
    popular: 'Populärt',
    sports: 'Sporter',
    football: 'Fotboll',
    ice_hockey: 'Ishockey',
    my_bonus: 'Mina bonusar',
    tennis: 'Tennis',
    jackpot: 'PoolX',
    winter_sports: 'Vintersport',
    bandy: 'Bandy',
    american_football: 'Amerikansk Fotboll',
    basketball: 'Basket',
    cycling: 'Cykel',
    esports: 'Esport',
    all_sports: 'Alla sporter',
    download_app: 'Ladda ner vår App, tillgänglig på följande enheter',
    re_captcha: 'This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.',
    about_us: 'Om oss',
    careers: 'Jobba hos oss',
    how_to_play: 'Så här spelar du',
    blog: 'Blogg',
    help_center: 'Hjälpcenter',
    terms_and_conditions: 'Regler och villkor',
    privacy_policy: 'Privacy Policy',
    game_rules: 'Spelregler',
    need_help: 'Behöver du hjälp?',
    visit_our_help_center: 'Om du har några frågor, vänligen besök vårt',
    search_sports_leagues_or_teams: 'Sök efter sporter, ligor eller lag',
    create_account: 'Bli medlem',
    log_in: 'Logga In',
    transfer: 'Överföring',
    deposit: 'Insättning',
    withdrawal: 'Uttag',
    account_history: 'Kontohistorik',
    account_settings: 'Min spelkoll',
    my_limits: 'Mina gränser',
    history: 'Spelhistorik',
    activity_check: 'Mina spelvanor',
    take_a_break: 'Spelpaus',
    log_out: 'Logga ut',
    champions_league: 'Champions League',
    the_championship: 'The Championship',
    premier_league: 'Premier League',
    la_liga: 'La Liga',
    serie_a: 'Serie A',
    bundes_league: 'Bundesliga',
    horse_betting: 'Trav',
    basket: 'Basket',
    password: 'Lösenord',
    close: "Stäng",

    // Current PoolXBet design, to be discarded
    withdraw: 'Uttag',
    withdraw_title: 'Hur mycket vill du ta ut?',
    play_other: 'Spela annat',
    our_activity_monitoring: 'Vår spelkoll',
    contact_us: 'Kontakta Oss',
    sports_service: 'Sportservice',
    transactions: 'Transaktioner',
    more: 'Mer',
    start: 'Start',
    swedish: 'Svenska',
    english: 'Engelska',
    my_habits: 'Mina Spelvanor',
    log_in_poolxbet: 'Logga in på PoolXBet',
    log_out_caps: 'LOGGA UT',
    poolxbets_casino: 'PoolXBet',
    menu: 'Meny',
    ok: 'OK',
    cancel: 'Avbryt',
    username: 'Användarnamn',
    amount: 'Belopp',
    deposit_and_play: 'Sätt in och Spela!',
    deposit_now: 'Sätt in Nu!',
    my_activity: 'Min Aktivitet',
    transfers: 'Överföringar',
}