import React, { useState } from 'react';
import PopupDialog from "./common/PopupDialog";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions, getTransactions } from "./transactions";
import { useTranslation } from "react-i18next";
import Transactions from "./transactions/Transactions";
import { CircularProgress } from '@mui/material';

export default () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch()
  const transactions = useSelector(getTransactions);

  return (
    <span>
      <PopupDialog title={t("transactions")} open={open} onClose={() => setOpen(false)}>
        {transactions == null && <CircularProgress />}
        {transactions != null && <Transactions transactions={transactions} />}
      </PopupDialog>
      <span style={{ cursor: 'pointer', fontSize: 14, opacity: .5, }} onClick={() => {
        setOpen(true);
        dispatch(fetchTransactions());
      }}>{t("transactions")}</span>
    </span>
  );
}