import React, { useEffect, useState } from 'react';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { fetchApiRoot } from "./api";
import TopBar from './TopBar/index';
import { getPlayer, reloadPlayer } from "./player";
import useInterval from "react-useinterval";
import LeftSidebar from './LeftSidebar';
import "./translations/i18n";
import { useTranslation } from "react-i18next";
import Footer from './Footer';
import i18n from "i18next";
import IframeResizer from 'iframe-resizer-react';
import Affiliate from './Affiliate/affiliate';
import { Typography } from '@mui/material';

const POOLX_MESSAGE_SOURCE = 'poolx_game_client';

const GameArea = ({ frameLang, frameTheme, frameRoundId, frameSyndicateId }) => {
  const player = useSelector(getPlayer);
  const dispatch = useDispatch();
  const { t } = useTranslation();


  const handleIframeMessage = (message) => {
    if (message && message.source && message.source === POOLX_MESSAGE_SOURCE) {
      console.debug("received message from the game iframe", message);
      if (message.message_type && message.message_type === 'scroll_to_top') {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
      return;
    }
    if (message === 'scroll_to_top') {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;      
    }    
  }

  window.addEventListener("message", (event) => {
    handleIframeMessage(event.data);
  }, false);


  // Poll wallet when game is open
  useInterval(() => {
    dispatch(reloadPlayer());
  }, player ? 5000 : null);

  if (player) {

    let roundId = "";

    if (frameRoundId) {
      roundId = `&navigationKey=round&navigationId=${frameRoundId}`;
    }

    let syndicateId = "";

    if (frameSyndicateId) {
      syndicateId = `&navigationKey=syndicate&navigationId=${frameSyndicateId}`;
    }

    const gameUrl = `${process.env["REACT_APP_GAME_URL"]}?operatorId=MOCKOP&operatorPlayerId=${player.id}&operatorToken=${player.token}&lang=${frameLang}&theme=${frameTheme}${roundId}${syndicateId}`;

    return (<IframeResizer
      title="game"
      className={'max-width'}
      scolling="no"
      heightCalculationMethod={'documentElementScroll'}
      frameBorder={0}
      src={gameUrl} />
    );
  } else {
    return (
      <div className="logged-out-div">
        <Typography sx={{ marginTop: '2rem' }} variant={'h1'}>{t("log_in_to_play")}</Typography>
      </div>
    );
  }
}

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchApiRoot());
  });

  const [showMenu, setShowMenu] = useState(window.screen.width >= 1212);
  const [showAffiliate, setShowAffiliate] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const lang = searchParams.get('lang') ? searchParams.get('lang') : i18n.languages[0];
  const theme = searchParams.get('theme') ? searchParams.get('theme') : 'light';
  const round = searchParams.get('roundId') ? searchParams.get('roundId') : undefined;
  const syndicate = searchParams.get('syndicateId') ? searchParams.get('syndicateId') : undefined;

  i18n.changeLanguage(lang);

  return (
    <Router>
      <Switch>
        <Route path={"/"}>
          {showAffiliate === true ? <Affiliate /> :
            <div className="wrapper">
              <TopBar searchParams={searchParams} selectedLanguage={lang} setShowMenu={setShowMenu} showMenu={showMenu} selectedTheme={theme} />
              <div className="side-bars">
                <div className="game-flex-div">
                  {showMenu ? <LeftSidebar /> : <div />}
                  <GameArea frameLang={lang} frameTheme={theme} frameRoundId={round} frameSyndicateId={syndicate}/>
                </div>
              </div>
              <Footer searchParams={searchParams} selectedLanguage={lang} selectedTheme={theme} setShowAffiliate={setShowAffiliate}/>
            </div>
          }
        </Route>
      </Switch>
    </Router >);
}

export default App;
