import * as t from './actionTypes';
import fetchApi from "../backend/fetchApi";
import {getApi} from "./selectors";
import {fetchPlayer} from "../player";

export const requestApi = () => ({
    type: t.REQUEST_API
});

export const receiveApi = (api) => ({
    type: t.RECEIVE_API,
    api
});

export const requestAuth = () => ({
  type: t.REQUEST_AUTH
});

export const receiveAuth = (auth) => ({
  type: t.RECEIVE_AUTH,
  auth
});

export const fetchApiRoot = () =>
    (dispatch) => {
        dispatch(requestApi());

        return fetchApi("/")
          .then(response => response.json())
          .then(json => {
            dispatch(receiveApi(json))
            dispatch(fetchMe());
          })
    };

export const fetchMe = () =>
  (dispatch, getState) => {
    const api = getApi(getState());

    if (api._links.me) {
      dispatch(fetchPlayer(api._links.me.href));
    }
  }

export const auth = (request) =>
  (dispatch, getState) => {
    const api = getApi(getState());

    if (api._links.auth) {
      dispatch(requestAuth())

      return fetchApi(api._links.auth.href, {
        method: 'POST',
        body: JSON.stringify(request)
      }).then(response => response.json())
        .then(json => {
          localStorage.setItem("mockop_token", json.token)
          dispatch(receiveAuth(json));
          dispatch(fetchApiRoot());
        })
    }
  }

