import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import {useTranslation} from "react-i18next";

export default function PopupDialog({open, onClose, onOk = () => undefined, title, children, cancelLabel = "Cancel", okLabel = "Ok"}) {
  const { t } = useTranslation();
  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { onClose(); }} color="primary">
            {t("cancel")}
          </Button>
          <Button onClick={() => { onClose(); onOk(); }} color="primary">
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}