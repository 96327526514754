import React, { useState } from 'react';
import PopupDialog from "./common/PopupDialog";
import { useTranslation } from "react-i18next";
import { DialogContentText, TextField } from '@mui/material';

export default ({ onWithdraw }) => {
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState('');
  const { t } = useTranslation();

  return (
    <span>
      <PopupDialog okLabel={t("withdraw")} onClose={() => setOpen(false)} onOk={() => onWithdraw({ amount })}
        title={t("withdraw")} open={open}>
        <DialogContentText>
          {t("withdraw_title")}
      </DialogContentText>
        <TextField
          value={amount}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
              setOpen(false);
              onWithdraw({ amount })
            }
          }}
          onChange={e => setAmount(e.target.value)}
          autoFocus
          margin="dense"
          id="name"
          label={t("amount")}
          type="numeric"
          fullWidth
        />
      </PopupDialog>
      <span color='inherit' variant={"outlined"} onClick={() => setOpen(true)} style={{ cursor: 'pointer', fontSize: 14, opacity: .5, }}>{t("withdraw")}</span>
    </span>
  );
}