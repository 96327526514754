var apiUrl = process.env["REACT_APP_API_URL"]

export const fetchApiRaw = (url, options) => {
  return fetch(new URL(url, apiUrl + "/").href.replace(/\/$/, ""), options);
}

const fetchApi = (url, options = {}) => {
  const headers =
    new Headers({
      Accept: 'application/json',
      ...(options.headers || {})
    });
  if (
    !headers.has('Content-Type') &&
    !(options && (!options.method || options.method === 'GET')) &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    headers.set('Content-Type', 'application/json');
  }

  const token = localStorage.getItem("mockop_token")
  if (token) {
    headers.set("Authorization", "Bearer " + token)
  }

  return fetchApiRaw(url, {...options, headers});
}

export default fetchApi;