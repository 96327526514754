import React from 'react';
import PopupDialog from "../common/PopupDialog";
import { useTranslation } from "react-i18next";
import styles from './LoginButton.module.css';
import { DialogContentText, TextField } from '@mui/material';

export default ({ onLogin }) => {
  const [open, setOpen] = React.useState(false);
  const [username, setUsername] = React.useState("")
  const [password, setPassword] = React.useState("")
  const { t } = useTranslation();

  if (open) {
    return (
      <>
        <PopupDialog okLabel={t("log_in")} onClose={() => setOpen(false)} onOk={() => onLogin({ username, password })} title={t("log_in")} open={open}>
          <DialogContentText>{t("log_in_poolxbet")}</DialogContentText>
          <TextField
            value={username}
            onChange={e => setUsername(e.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();
                setOpen(false);
                onLogin({ username, password })
              }
            }}
            autoFocus
            margin="dense"
            id="name"
            label={t("username")}
            type="text"
            fullWidth
          />
          <TextField
            value={password}
            onChange={e => setPassword(e.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();
                setOpen(false);
                onLogin({ username, password })
              }
            }}
            autoFocus
            margin="dense"
            id="name"
            label={t("password")}
            type="password"
            fullWidth
          />
        </PopupDialog>
        <div className={styles.btnFlex}>
          <span className={styles.signUpBtn}>{t('create_account')}</span>
          <span className={styles.logInBtn}>{t("log_in")}</span>
        </div>
      </>
    );
  } else {
    return (
      <div className={styles.btnFlex}>
        <span className={styles.signUpBtn} onClick={() => setOpen(true)}>{t('create_account')}</span>
        <span className={styles.logInBtn} onClick={() => setOpen(true)}>{t("log_in")}</span>
      </div>
    );
  }
}