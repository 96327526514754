import { Container, Grid, Link, Paper, Typography } from '@mui/material';
import moment from 'moment/moment';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

function getFriendlyName(name) {

    if (name.indexOf("Max") > -1) {
        return "Max 13";
    }
    if (name.indexOf("Mix") > -1) {
        return "Mix 12";
    }
    if (name.indexOf("Flex") > -1) {
        return "Flex 8";
    }

    return name;
}

function getGradient(name) {

    let gradient = 'linear-gradient(335deg,#A8301F,#FA9547,#D09C72,#B74D01)';

    if (name.indexOf("Max") > -1) {
        gradient = 'linear-gradient(335deg,#FBBA2E,#FFEDC0,#DD9F00)';
    }
    if (name.indexOf("Mix") > -1) {
        gradient = 'linear-gradient(335deg,#A8A8A8,#DEDEDE,#A3ADC0,#A8A8A8)';
    }

    return gradient;
}

function getStroke(name) {

    let strokeColor = "#FFB183";

    if (name.indexOf("Max") > 0) {
        strokeColor = "#FFD700";
    }

    if (name.indexOf("Mix") > 0) {
        strokeColor = "#DCDCDC";
    }

    return strokeColor;
}

function Affiliate() {

    let [poolXCoupons, setPoolXCoupons] = useState(undefined);
    let [poolXSyndicates, setPoolXSyndicates] = useState(undefined);

    useEffect(() => {

        fetch('https://publicdata.demo.poolxbet.com/rounds/').then((response) => {
            return response.json();
        }).then(((json) => {
            setPoolXCoupons(json.rounds);
        }))

        fetch('https://publicdata.demo.poolxbet.com/syndicates/?operatorId=MOCKOP').then((response) => {
            return response.json();
        }).then(((json) => {
            setPoolXSyndicates(json.syndicates);
        }))
    }, []
    )


    if (poolXCoupons === undefined || poolXCoupons?.length === 0) {
        return <></>;
    }

    poolXCoupons = poolXCoupons.sort((a, b) => b.turnoverUnits - a.turnoverUnits);

    let syndicatesToShow = [];


    if (poolXSyndicates !== undefined) {
        poolXSyndicates.forEach(syndicate => {
            let round = poolXCoupons.find(r => r.id === syndicate.roundId.toString());
            if (round !== undefined) {

                let roundDate = new Date(round.openTo);
                let now = new Date();

                if (roundDate > now) {
                    syndicatesToShow.push(syndicate);
                }
            }

        });
    }

    return (

        <Container maxWidth="sm">
            <Grid container direction='column'>
                {poolXCoupons &&
                    poolXCoupons.map((round, index) => {

                        let rate = round.unitAmounts["EUR"];
                        let jackpot = round.jackpotUnits * rate;
                        let name = getFriendlyName(round.name);
                        let openTo = round.openTo;
                        let turnover = round.turnoverUnits * rate;

                        return (
                            <Link key={'link' + index} href={'/?roundId=' + round.id} style={{ textDecoration: 'none' }}>
                                <Paper sx={{
                                    margin: '20px', padding: '20px', width: '600px', backgroundImage: getGradient(round.name),
                                    backgroundSize: '800px 400px'
                                }}>
                                    <Grid container alignItems='center' direction='column'>
                                        <Typography sx={{
                                            fontSize: "38px",
                                            color: "#1C1818",
                                            lineHeight: "57px",
                                            fontWeight: "900",
                                            fontFamily: "Arial",
                                            WebkitTextStrokeWidth: "1px",
                                            WebkitTextStrokeColor: getStroke(round.name)
                                        }}>
                                            {name}
                                        </Typography>

                                        <Typography sx={{
                                            fontSize: "24px",
                                            color: "#1C1818",
                                            fontWeight: "900",
                                            fontFamily: "Arial",
                                        }}>
                                            {moment(openTo).format('dddd')}
                                        </Typography>

                                        <Typography sx={{
                                            fontSize: "24px",
                                            color: "#1C1818",
                                            fontWeight: "900",
                                            fontFamily: "Arial"
                                        }}>
                                            {"Jackpot " + new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(jackpot)}
                                        </Typography>
                                        <Typography sx={{
                                            fontSize: "24px",
                                            color: "#1C1818",
                                            fontWeight: "900",
                                            fontFamily: "Arial",
                                        }}>
                                            {"Current pool " + new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(turnover)}
                                        </Typography>
                                    </Grid>
                                </Paper>
                            </Link>
                        );
                    })
                }

            </Grid>
            <Typography sx={{
                fontSize: "24px",
                color: "#1C1818",
                fontWeight: "900",
                fontFamily: "Arial",
            }}>Syndicates</Typography>
            <Grid container direction='column'>
                {syndicatesToShow &&
                    syndicatesToShow.map((syndicate, index) => {

                        return (
                            <Link key={'link' + index} href={'/?syndicateId=' + syndicate.id} style={{ textDecoration: 'none' }}>
                                <Paper sx={{
                                    margin: '20px', padding: '20px', width: '600px',
                                    backgroundSize: '800px 400px', backgroundColor: 'lightgray'
                                }}>
                                    <Grid container alignItems='center' direction='column'>
                                        <Typography>{syndicate.name}</Typography>
                                        <Typography>Price per share: {syndicate.stakePerShareAmount} {syndicate.stakePerShareCurrency}</Typography>
                                        <Typography>Number of shares: {syndicate.maxShareCount}</Typography>
                                    </Grid>
                                </Paper>
                            </Link>
                        );
                    })
                }

            </Grid>
        </Container>


    );

}

export default Affiliate;