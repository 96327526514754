import * as t from './actionTypes';
import fetchApi from "../backend/fetchApi";
import {getPlayer} from "./selectors";
import {fetchApiRoot} from "../api";

export const requestPlayer = () => ({
    type: t.REQUEST_PLAYER
});

export const receivePlayer = (player) => ({
    type: t.RECEIVE_PLAYER,
    player
});

export const logout = () => ({
  type: t.LOGOUT,
});

export const doLogout = () =>
  dispatch => {
    localStorage.removeItem("mockop_token")
    dispatch(logout());
    dispatch(fetchApiRoot());
  };


export const fetchPlayer = (url) =>
  (dispatch) => {
    dispatch(requestPlayer());

    fetchApi(url)
      .then(response => response.json())
      .then(json => {
        dispatch(receivePlayer(json))
      });
  };

export const reloadPlayer = () =>
  (dispatch, getState) => {
    const player = getPlayer(getState());

    if (player && player._links.self) {
      dispatch(fetchPlayer(player._links.self.href));
    }
  }


export const deposit = (request) =>
  (dispatch, getState) => {
    const player = getPlayer(getState());

    if (player && player._links.deposit) {
      return fetchApi(player._links.deposit.href, {
        method: 'POST',
        body: JSON.stringify(request)
      }).then(response => dispatch(reloadPlayer()))
    }
  }


export const withdraw = (request) =>
  (dispatch, getState) => {
    const player = getPlayer(getState());

    if (player && player._links.withdraw) {
      return fetchApi(player._links.withdraw.href, {
        method: 'POST',
        body: JSON.stringify(request)
      }).then(response => dispatch(reloadPlayer()))
    }
  }

