export const TRANSLATIONS_EN = {
    welcome: 'Welcome',
    log_in_to_play: 'Log in to play',
    odds: 'Odds',
    home: 'Home',
    live: 'Live',
    pool_x: 'PoolX',
    starting_soon: 'Starting soon',
    current: 'Current',
    promotion: 'Promotion',
    bet_history: 'Bet History',
    popular: 'Popular',
    sports: 'Sports',
    football: 'Football',
    ice_hockey: 'Ice Hockey',
    my_bonus: 'My bonus',
    jackpot: 'PoolX',
    tennis: 'Tennis',
    winter_sports: 'Winter Sports',
    bandy: 'Bandy',
    american_football: 'American Football',
    basketball: 'Basketball',
    cycling: 'Cycling',
    esports: 'Esports',
    all_sports: 'All Sports',
    download_app: 'Download our App, available on these devices',
    re_captcha: 'This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.',
    about_us: 'About Us',
    careers: 'Careers',
    how_to_play: 'How to play',
    blog: 'Blog',
    help_center: 'Help Center',
    terms_and_conditions: 'Terms and Conditions',
    privacy_policy: 'Privacy Policy',
    game_rules: 'Game Rules',
    need_help: 'Need help?',
    visit_our_help_center: 'If you have a question, please visit our',
    search_sports_leagues_or_teams: 'Search Sports Leaues Or Teams',
    create_account: 'Create Account',
    log_in: 'Log In',
    transfer: 'Transfer',
    deposit: 'Deposit',
    withdrawal: 'Withdrawal',
    account_history: 'Account History',
    account_settings: 'Account Settings',
    my_limits: 'My Limits',
    history: 'History',
    activity_check: 'Activity Check',
    take_a_break: 'Take A Break',
    log_out: 'Log Out',
    champions_league: 'Champions League',
    the_championship: 'The Championship',
    premier_league: 'Premier League',
    la_liga: 'La Liga',
    serie_a: 'Serie A',
    bundes_league: 'Bundes league',
    horse_betting: 'Horse Betting',
    basket: 'Basketball',
    password: 'Password',
    close: "Close",

    // Current PoolXBet design, to be discarded
    withdraw: 'Withdraw',
    withdraw_title: 'What? You want to withdraw???',
    play_other: 'Play something else',
    our_activity_monitoring: 'Activity monitoring',
    contact_us: 'Contact Us',
    sports_service: 'Sports Service',
    transactions: 'Transactions',
    more: 'More',
    start: 'Start',
    swedish: 'Swedish',
    english: 'English',
    farsi: 'فارسی',
    my_habits: 'My Habits',
    log_in_poolxbet: 'Log in to PoolXBet',
    log_out_caps: 'LOG OUT',
    poolxbets_casino: 'PoolXBet',
    menu: 'Menu',
    ok: 'OK',
    cancel: 'Cancel',
    username: 'Username',
    amount: 'Amount',
    deposit_and_play: 'Deposit and Play!',
    deposit_now: 'Deposit Now!',
    my_activity: 'My Activity',
    transfers: 'Transfers',
}