import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import { auth } from "../api";
import { getPlayer, deposit } from "../player";
import LoginButton from "../LoginButton";
import RightSidebar from '../RightSidebar';
import LangSelector from '../common/LangSelector';

import styles from './TopBar.module.css';
import {BurgerIcon, UserIcon, XIcon} from '../icons';
import DepositButton from '../DepositButton';
import MediaQuery from "react-responsive/src";

const balanceString = (amount, currency) => {
  amount = parseFloat(amount).toFixed(2);
  
  if ('USD' === currency) {
    return `$ ${amount}`;
  }

  if ('SEK' === currency) {
    return `${amount} kr`;
  }

  if ('EUR' === currency) {
    return `€ ${amount}`;
  }

  if ('GBP' === currency) {
    return `£ ${amount}`;
  }

  return `${currency} ${amount}`
}

const menuBtn = (t, showingMenu) => {
  if (showingMenu) {
    return (
        <div className={styles.closeMenuButton}>
          <div className={styles.menuDiv}>
            <XIcon />
            <span className={styles.menuFont}>{t("close")}</span>
          </div>
        </div>
    );
  }

  return (
      <div className={styles.menuDiv}>
        <BurgerIcon />
        <span className={styles.menuFont}>{t("menu")}</span>
      </div>
  );
}

const TopBar = ({ selectedLanguage, searchParams, showMenu, setShowMenu, selectedTheme }) => {
  const player = useSelector(getPlayer);
  const dispatch = useDispatch();
  const onLogin = request => dispatch(auth(request));
  const onDeposit = request => dispatch(deposit(request));
  const balance = player && player.balance ? player.balance : 0;
  const { t } = useTranslation();
  const [profileClicked, setProfileClicked] = useState(false);

  return (
    <>
      {profileClicked && <div className={styles.overlay} onClick={() => setProfileClicked(false)} />}
      <div className={styles.topBar}>
        <div onClick={() => setShowMenu(!showMenu)}>
          {menuBtn(t, showMenu)}
        </div>
        <h6 className={styles.title}>{t("poolxbets_casino")}</h6>

        <div className={styles.profileDiv}>
          {player ? (
            <div className={styles.flexCenter}>
              <div className={styles.balanceWrap}>
                <span className={styles.profileFont}>{balanceString(balance.amount, balance.currency)}</span>
                <DepositButton onDeposit={onDeposit} isIcon />
              </div>

              <div onClick={() => setProfileClicked(!profileClicked)} className={styles.userIconWrap}>
                <UserIcon />
              </div>
            </div>
          ) : (
            <span className={styles.profileFont}>
              <LoginButton onLogin={onLogin} />
            </span>
          )}
          <MediaQuery minWidth={641}>
            <LangSelector searchParams={searchParams} selectedLanguage={selectedLanguage} />            
          </MediaQuery>
        </div>
      </div>

      <RightSidebar clicked={profileClicked} close={setProfileClicked} />
    </>
  );
}

export default TopBar;