import moment from "moment/moment";
import React from "react";

const Transactions = ({transactions}) =>
  <div>
    {transactions.map(t =>
      <div style={{display: 'flex'}}>
        <div style={{width: '6em'}}>{t.transactionType}</div>
        <div style={{width: '12em'}}>{moment(t.createdAt).format()}</div>
        <div style={{flexGrow: 1}}>{t.amount}</div>
      </div>
    )}
  </div>;

export default Transactions;
