import React from 'react';
import styles from './RightSidebar.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { getPlayer, deposit, withdraw, doLogout } from '../player';
import { auth, getApi } from '../api';
import { formatAmount } from '../money/moneyFunctions';
import DepositButton from '../DepositButton';
import WithdrawButton from '../WithdrawButton';
import TransactionsButton from '../TransactionsButton';
import LoginButton from '../LoginButton';
import { useTranslation } from "react-i18next";
import { UserIcon, WalletIcon, DepositsIcon, MoneyIcon, HandIcon, ReversedStartingSoonIcon } from '../icons';

export default function RightSidebar({
  clicked,
  close
}) {
  const api = useSelector(getApi);
  const player = useSelector(getPlayer);
  const dispatch = useDispatch();
  const onLogin = request => dispatch(auth(request));
  const onDeposit = request => dispatch(deposit(request));
  const onWithdraw = request => dispatch(withdraw(request));
  const name = player ? player.username : ''; // maybe use player.username here
  const { t } = useTranslation();

  return (
    <div className={`${styles.wrapper} ${clicked ? styles.slideIn : ''}`}>
      {(api != null) && player ? (
        <>
          <div className={styles.topInfoDiv}>
            <div className={styles.nameFlex}>
              <span className={styles.bold}>{name}</span>
              <div className={styles.userIconWrap}>
                <UserIcon />
              </div>
            </div>
            <div className={styles.moneyDiv}>
              <WalletIcon />
              <span className={styles.moneySpan}>{formatAmount(player.balance)}</span>
            </div>
          </div>

          <div className={styles.bottomInfoDiv}>
            <div>
              <div className={styles.transactionDiv}>
                <span className={`${styles.headerSpan} ${styles.bold}`}>{t("transfers")}</span>
                <div className={styles.headerContent}>
                  <div className={styles.iconFlex}>
                    <DepositsIcon />
                    <DepositButton onDeposit={onDeposit} />
                  </div>
                  <div className={styles.iconFlex}>
                    <MoneyIcon />
                    <WithdrawButton onWithdraw={onWithdraw} />
                  </div>
                  <div className={styles.iconFlex}>
                    <ReversedStartingSoonIcon />
                    <TransactionsButton />
                  </div>
                </div>
              </div>

              <div className={styles.myGameCheck}>
                <span className={`${styles.headerSpan} ${styles.bold}`}>{t("my_activity")}</span>
                <div className={styles.headerContent}>
                  <div className={styles.iconFlex}>
                    <HandIcon />
                    <span className={styles.stopSpan}>{t("my_limits")}</span>
                  </div>
                  <div className={styles.iconFlex}>
                    <HandIcon />
                    <span className={styles.stopSpan}>{t("history")}</span>
                  </div>
                  <div className={styles.iconFlex}>
                    <HandIcon />
                    <span className={styles.stopSpan}>{t("my_habits")}</span>
                  </div>

                  <div className={styles.iconFlex}>
                    <HandIcon />
                    <span className={styles.stopSpan}>{t("take_a_break")}</span>
                  </div>
                </div>
              </div>
            </div>

            <button className={styles.logoutBtn} onClick={() => {
              dispatch(doLogout());
              close(false);

            }}>
              <span>{t("log_out_caps")}</span>
            </button>

          </div>
        </>
      ) : (
          <LoginButton onLogin={onLogin} />
        )}
    </div>
  );
}