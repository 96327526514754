import * as t from './actionTypes';
import fetchApi from "../backend/fetchApi";
import {getPlayer} from "../player";

export const requestTransactions = () => ({
    type: t.REQUEST_TRANSACTIONS
});

export const receiveTransactions = (transactions) => ({
    type: t.RECEIVE_TRANSACTIONS,
    transactions
});

export const fetchTransactions = () =>
  (dispatch, getState) => {
    const player = getPlayer(getState());

    if (!player) return

    dispatch(requestTransactions());

    if (player && player._links.transactions) {
      return fetchApi(player._links.transactions.href)
        .then(response => response.json())
        .then(json => {
          dispatch(receiveTransactions(json._embedded.transactions));
        })
    }
  }

