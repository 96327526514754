import { combineReducers } from 'redux';
import {reducer as api} from './api';
import {reducer as player} from './player';
import {reducer as transactions} from './transactions';

export default combineReducers({
    api,
    player,
    transactions
});

