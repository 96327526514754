import React from 'react';
import styles from './LeftSidebar.module.css';
import { useTranslation } from 'react-i18next';
import { StartIcon, LiveNowIcon, JackpotIcon, StartingSoonIcon, CurrentIcon, BonusIcon, HistoryIcon } from '../icons';

export default function LeftSidebar({setShowMenu}) {

  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerDiv}>
        <div className={styles.headerContent}>
          <div className={styles.iconFlex}>
            <StartIcon />
            <span>{t('start')}</span>
          </div>
          <div className={styles.iconFlex}>
            <LiveNowIcon />
            <span>{t('live')}</span>
          </div>
          <div className={styles.iconFlex}>
            <JackpotIcon />
            <span>{t('jackpot')}</span>
          </div>
          <div className={styles.iconFlex}>
            <StartingSoonIcon />
            <span>{t('starting_soon')}</span>
          </div>
          <div className={styles.iconFlex}>
            <CurrentIcon />
            <span>{t('current')}</span>
          </div>
          <div className={styles.iconFlex}>
            <BonusIcon />
            <span>{t('my_bonus')}</span>
          </div>
          <div className={styles.iconFlex}>
            <HistoryIcon />
            <span>{t('bet_history')}</span>
          </div>
        </div>

        <div className={styles.popularDiv}>
          <span className={styles.spanMargin}>{t('popular')}</span>

          <div className={styles.listFlex}>
            <span>{t('tennis')}</span>
            <span className={styles.smallSpan}>{t('tennis')}</span>
          </div>
          <div className={styles.listFlex}>
            <span>{t('champions_league')}</span>
            <span className={styles.smallSpan}>{t('football')}</span>
          </div>
          <div className={styles.listFlex}>
            <span>{t('premier_league')}</span>
            <span className={styles.smallSpan}>{t('football')}</span>
          </div>
          <div className={styles.listFlex}>
            <span>{t('the_championship')}</span>
            <span className={styles.smallSpan}>{t('football')}</span>
          </div>
          <div className={styles.listFlex}>
            <span>{t('la_liga')}</span>
            <span className={styles.smallSpan}>{t('football')}</span>
          </div>
          <div className={styles.listFlex}>
            <span>{t('serie_a')}</span>
            <span className={styles.smallSpan}>{t('football')}</span>
          </div>
          <div className={styles.listFlex}>
            <span>{t('bundes_league')}</span>
            <span className={styles.smallSpan}>{t('football')}</span>
          </div>
        </div>

        <div className={styles.sportsDiv}>
          <span className={styles.sportsMargin}>{t('sports')}</span>
          <span className={styles.gray}>{t('football')}</span>
          <span className={styles.gray}>{t('ice_hockey')}</span>
          <span className={styles.gray}>{t('tennis')}</span>
          <span className={styles.gray}>{t('horse_betting')}</span>
          <span className={styles.gray}>{t('winter_sports')}</span>
          <span className={styles.gray}>{t('bandy')}</span>
          <span className={styles.gray}>{t('american_football')}</span>
          <span className={styles.gray}>{t('basket')}</span>
          <span className={styles.gray}>{t('cycling')}</span>
          <span className={styles.gray}>{t('esports')}</span>

          <button className={styles.btn}>
            {t('all_sports')}
          </button>
        </div>
      </div>
    </div>
  );
}