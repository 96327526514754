import React from "react";
import styles from "./LangSelector.module.css";

const ThemeSelector = ({ searchParams, selectedTheme }) => {
    return (
        <div>
            <div className={styles.flexCenter}>
                <select className={styles.select} value={selectedTheme} onChange={(e) => {
                    searchParams.set("theme", e.target.value);
                    window.location.search = searchParams.toString();
                }}>
                    <option value="light">Light</option>
                    <option value="blue" >Blue</option>
                    <option value="gold" >Gold</option>
                    <option value="medina">Medina</option>
                    <option value="sultan">Sultan</option>
                    <option value="jet" >Jet</option>
                    <option value="toto">Toto</option>
                    <option value="blue2">Blue2</option>
                    <option value="betconstruct">Betconstruct</option>
                    <option value="win90" >Win90</option>
                    <option value="manoto">Manoto</option>
                    <option value="betfa" >Betfa</option>
                    <option value="takbet">Takbet</option>
                    <option value="marcbet">Marcbet</option>
                    <option value="irbet365">Irbet365</option>
                    <option value="elit">Elit</option>
                    <option value="metro">Metro</option>
                    <option value="kung" >Kung</option>
                    <option value="dbet">Dbet</option>
                    <option value="cwinz">Cwinz</option>
                    <option value="betbeto">Betbeto</option>
                    <option value="orange">Orange</option>
                    <option value="wood">Wood</option>
                    <option value="lion">Lion</option>
                </select>
            </div>
        </div>
    );
}

export default ThemeSelector;