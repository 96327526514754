import * as t from './actionTypes';

const initialState= {
    isFetching: false,
    item: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case t.REQUEST_API:
            return {
                ...state,
                isFetching: true
            };
        case t.RECEIVE_API:
            return {
                ...state,
                isFetching: false,
                item: action.api
            };
        default:
            return state
    }
};